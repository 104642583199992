<template>
  <div class="border-b border-grey600 relative py-16">
    <div class="flex">
      <div class="h-40 w-40 mr-12">
        <img
          class="max-h-40 max-w-40 object-contain"
          :src="item.imageUrl"
          height="40"
          width="40"
          :alt="item.name"
        />
      </div>
      <div class="flex-1">
        <div class="flex justify-between">
          <div class="small-base-text thunder-font">
            {{ item.name }}
          </div>
          <div
            class="small-base-text flex-shrink-0 ml-16"
            :class="{
              'text-error': isSample,
              'text-grey800': !item.hasDiscount || !showDiscount,
              'opacity-50': priceLoading,
            }"
          >
            {{ item.price }}
          </div>
        </div>
        <div class="flex justify-between items-center mt-12">
          <div class="flex">
            <div
              class="flex min-w-88 items-center border border-grey600 bg-white rounded justify-between small-base-text"
              :class="{
                'opacity-25': disabled,
              }"
            >
              <div
                class="pl-12 pr-16 py-4 cursor-pointer select-none small-base-text"
                @click="decreaseQuantity"
              >
                &mdash;
              </div>
              {{ count }}
              <div
                class="pr-12 pl-16 py-4 cursor-pointer select-none small-base-text"
                @click="increaseQuantity"
              >
                +
              </div>
            </div>
            <div
              v-if="!!errorMessage"
              class="bg-error rounded small-base-text py-4 px-12 ml-8 text-grey200"
            >
              {{ errorMessage }}
            </div>
          </div>
          <div
            class="cursor-pointer select-none"
            :class="{
              'opacity-25': disabled,
            }"
            @click="deleteItem"
          >
            <img
              src="~/assets/icons/close.png"
              class="h-16 w-16"
              height="16"
              width="16"
            />
          </div>
        </div>
      </div>
    </div>
    <div v-if="item.notices && item.notices.length && isCheckout" class="mt-24">
      <div
        v-for="(notice, index) in item.notices"
        :key="index"
        class="p-16 rounded-md bg-grey500 flex my-16"
      >
        <fa
          :icon="['fal', 'info-circle']"
          class="text-base mr-8 flex-shrink-0"
        ></fa>
        <div>
          <p v-if="notice.name" class="small-base-text mb-2">
            {{ notice.name }}
          </p>
          <p
            v-if="notice.description && notice.description.length < 80"
            class="small-base-regular-text"
          >
            {{ notice.description }}
          </p>
          <div v-else-if="notice.description">
            <p class="small-base-regular-text">
              {{ notice.description.substring(0, 80) + '...' }}
            </p>
            <div
              class="small-base-text underline mt-6 cursor-pointer"
              @click="showPopup(notice)"
            >
              {{ $lang('productListingResources', 'showMorePageText') }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <transition name="fade">
      <div
        v-if="showNoticeFlagPopup && isCheckout"
        class="fixed top-0 left-0 h-full w-full bg-grey900 bg-opacity-50 notice-flag-popup md:flex md:items-center md:justify-center"
        @mousedown.self="closePopup"
      >
        <div
          class="bg-white px-24 py-56 pb-32 relative md:pb-56 md:max-w-428 md:w-10/12"
        >
          <img
            src="~assets/icons/close.png"
            class="absolute right-20 top-20 h-24 w-24 cursor-pointer select-none"
            height="24"
            width="24"
            @click="closePopup"
          />
          <h5 class="preamble-text mb-12 md:text-preamble md:font-semibold">
            {{ noticeFlagTitle }}
          </h5>
          <p class="md:text-base">{{ noticeFlagDescription }}</p>
        </div>
      </div>
    </transition>
  </div>
</template>

<script setup lang="ts">
import _debounce from 'lodash/debounce';
import { type ICartItem, PurchaseType } from '~/models/api-types';
import { usePageContentStore } from '~/store/pageContent';
import { useCartStore } from '~/store/cart';
import * as Sentry from '@sentry/vue';

const pageStore = usePageContentStore();
const cartStore = useCartStore();

const { apiGet } = useApiFetch();
const { $lang } = useNuxtApp();

const props = defineProps<{
  item: ICartItem,
  purchaseType?: PurchaseType,
  disabled?: boolean,
  showDiscount?: boolean,
}>();

const count = ref(0);
const errorMessage = ref('');
const noticeFlagTitle = ref('');
const noticeFlagDescription = ref('');
const priceLoading = ref(false);
const showNoticeFlagPopup = ref(false);
// eslint-disable-next-line @typescript-eslint/no-unused-vars
let modifyQuantityDebounced = (newCount: number): void => {};

const isCheckout = computed(() => {
  return pageStore.pageType === 'CheckoutPage';
});

const isSample = computed(() => {
  return props.purchaseType === PurchaseType.Sampling;
});

watch(props.item, (newVal) => {
  if (newVal) {
    count.value = props.item?.quantity;
  }
});

onBeforeMount(() => {
  count.value = props.item?.quantity;
  modifyQuantityDebounced = _debounce(modifyQuantity, 300);
});

const decreaseQuantity = () => {
  if (!props.disabled) {
    const newCount = count.value - 1;
    count.value = newCount;
    emit('loadingPrice', true);
    priceLoading.value = true;

    modifyQuantityDebounced(newCount);
  }
};

const increaseQuantity = () => {
  if (!props.disabled) {
    const newCount = count.value + 1;
    count.value = newCount;
    emit('loadingPrice', true);
    priceLoading.value = true;

    modifyQuantityDebounced(newCount);
  }
};

const deleteItem = async() => {
  if (!props.disabled) {
    try {
      emit('loadingPrice', true);
      priceLoading.value = true;
      await cartStore.alterItemQuantity({
        item: {
          ...props.item,
          quantity: 0,
        },
        purchaseType: props.purchaseType || PurchaseType.Standard,
      });
      errorMessage.value = '';
    } catch (e) {
      Sentry.captureException(e);
    } finally {
      emit('loadingPrice', false);
      priceLoading.value = false;
    }
  }
};

const modifyQuantity = async(newCount: number) => {
  try {
    const res: unknown = await cartStore.alterItemQuantity({
      item: {
        ...props.item,
        quantity: newCount,
      },
      purchaseType: props.purchaseType || PurchaseType.Standard,
    });
    const message = (res || '') as string;
    errorMessage.value = message;
    if (message) {
      setTimeout(() => {
        errorMessage.value = '';
        count.value = props.item.quantity;
      }, 2000);
    }
    if (isCheckout.value && typeof (window._sw) != undefined) {
      window._sw(async (api) => {
        api.suspend();
        await updateIngrid();
        api.resume();
      });
    }
  } catch (e) {
    Sentry.captureException(e);
  } finally {
    emit('loadingPrice', false);
    priceLoading.value = false;
  }
};

const emit = defineEmits<{
  (event: 'loadingPrice', value: boolean): void,
}>();

const showPopup = (notice: { name: string; description: string })  => {
  noticeFlagTitle.value = notice.name;
  noticeFlagDescription.value = notice.description;
  showNoticeFlagPopup.value = true;
  document.body.classList.add('locked');
};

const closePopup = () => {
  showNoticeFlagPopup.value = false;
  noticeFlagTitle.value = '';
  noticeFlagDescription.value = '';
  document.body.classList.remove('locked');
};

const updateIngrid = async() => {
  await apiGet(
    `ingrid/update/?purchaseType=${props.purchaseType}&cartChanged=true`
  );
};
</script>
<style scoped>
.notice-flag-popup {
  backdrop-filter: blur(2px);
  z-index: 9999;
}
</style>
